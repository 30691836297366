.MuiCard-root {
  margin: 1em 0;
}

.legal ul {
  list-style-type: disclosure-closed;
}

.legal li {
  margin: 0.5em 0;
}

form {
  padding-bottom: 1em;
}

.legal .MuiCheckbox-root {
  color: red;
}

.privacy .MuiTypography-body1 {
  margin-bottom: 1em;
}

.mg-bottom {
  margin-bottom: 1em !important;
}
